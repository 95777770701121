import React from "react";
import { dateFromString } from "../../../utils/dateFromString";
import { Button } from "antd";
import { ModalCargarFactura } from "./_ModalCargarFactura";
import { MotivoRechazo } from "./_MotivoRechazo";

export const Factura = ({ orden }) => {
  const [open, setOpen] = React.useState(false);

  if (!orden.fechaCargaFactura)
    return (
      <div className="celdaNumero">
        <Button onClick={() => setOpen(true)} type="primary" size="small">
          cargar factura
        </Button>
        <MotivoRechazo motivo={orden.facturaMotivoRechazo} />
        <ModalCargarFactura open={open} setOpen={setOpen} orden={orden} />
      </div>
    );
  return (
    <div className="celdaNumero">
      {dateFromString(orden.fechaCargaFactura).soloFecha}
    </div>
  );
};
