import { Button } from "antd";
import React from "react";
import { ModalCargaComplemento } from "./_ModalCargaComplemento";

export const Complemento = ({ orden }) => {
  const [open, setOpen] = React.useState(false);

  if (!orden.fechaPagado) return <></>;
  return (
    <div style={{ textAlign: "center" }}>
      <Button danger size="small" onClick={() => setOpen(true)}>
        cargar
      </Button>
      <ModalCargaComplemento open={open} setOpen={setOpen} orden={orden}/>
    </div>
  );
};
