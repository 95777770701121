import { Alert } from 'antd'
import React from 'react'

export const Respuesta = ({ body }) => {

    if (body.respuestaOk)
        return (
            <Alert style={{ marginTop: 10 }} message={body.respuestaOk} type="success" showIcon />
        )

    if (body.respuestaErr)
        return (
            <Alert style={{ marginTop: 10 }} message={body.respuestaErr} type="error" showIcon />
        )

    return (<></>)
}
