import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import React from 'react'
import { NombreFile } from './_NombreFile'
import { ErrorFile } from './_ErrorFile'

export const File = ({ props, titulo, file, fileErr }) => {
    return (<>
        <div style={{ marginBottom: 20 }}>
            <div>
                <Upload {...props} style={{ width: '100%' }}>
                    <Button icon={<UploadOutlined />}>
                        {titulo}
                    </Button>
                </Upload>
            </div>
            <NombreFile file={file} />
            <ErrorFile error={fileErr} />
        </div>
    </>)
}
