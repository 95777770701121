import { Button, Modal } from "antd";
import React from "react";
import { FormaFilesFactura } from "./formaFilesFactura/FormaFilesFactura";
import { TituloModal } from "../../../components/tituloModal/TituloModal";

export const ModalCargarFactura = ({ open, setOpen, orden }) => {
  return (
    <Modal
      footer={[
        <Button key={1} onClick={() => setOpen(false)} size="small" danger>
          cerrar
        </Button>,
      ]}
      title={<TituloModal texto="Cargar Documentos de Factura" />}
      open={open}
      onCancel={() => setOpen(false)}
    >
      <FormaFilesFactura orden={orden} />
    </Modal>
  );
};
