import axios from "axios";
import FileDownload from "js-file-download";
import { GNP_API_KEY } from "../constants/urlGnp";
import { axiosClientSigsa } from "../lib/axios/axiosClientSigsa";

///////////////////////////////////////////////////////////////////////////////////////////

axios.interceptors.request.use(function (config) {

  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
})


export async function get(url) {
  try {
    const {data} = await axiosClientSigsa.get(url);
    return {code: 0, data};
  } catch (e) {
    console.log(e);
    if (e.response) {
      return {code: -1, error: e.response.data};
    } else {
      return {code: -1, error: "Error desconocido"};
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////
export async function post(url, body) {
  try {
    const {data} = await axiosClientSigsa.post(url, body);
    return {code: 0, data};
  } catch (e) {
    if (e.response) {
      console.log(e.response);
      return {code: -1, error: e.response.data};
    } else {
      console.log(e);
      return {code: -1, error: "Error desconocido"};
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////
export async function put(url, body) {
  try {
    const {data} = await axiosClientSigsa.put(url, body);
    return {code: 0, data};
  } catch (e) {
    if (e.response) {
      console.log(e.response);
      return {code: -1, error: e.response.data};
    } else {
      console.log(e);
      return {code: -1, error: "Error desconocido"};
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////

export async function dele(url) {
  try {
    const {data} = await axiosClientSigsa.delete(url);
    return {code: 0, data};
  } catch (e) {
    if (e.response) {
      console.log(e.response);
      return {code: -1, error: e.response.data};
    } else {
      console.log(e);
      return {code: -1, error: "Error desconocido"};
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////

export async function getFile(url, name) {
  let salida = {data: 0};

  await axiosClientSigsa({
    url,
    method: "GET",
    responseType: "blob", // Important
  })
    .then((response) => {
      FileDownload(response.data, name);
    })
    .catch((e) =>{
      console.log(e)
      return {code: -1, error: "Error al descargar archivo"};
    }
    );

  return salida;
}

////////////////////////////////////////

export const postFile = async (url, formData) => {
  try {
    const {data} = await axiosClientSigsa({
      method: "post",
      url,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    });
    return {code: 0, data};
  } catch (e) {
    console.log(e.response);
    if (e.response && e.response.data !== "") {
      return {code: -1, error: e.response.data};
    } else {
      return {code: -1, error: "Error al cargar archivo"};
    }
  }
};


/////////////////////////////////////////////

export async function GNP_POST(url, body) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios.defaults.headers.common = {
      "X-API-Key": GNP_API_KEY,
    };

    const {data} = await axios({
      method: "post",
      url,
      config,
      data: body,
    });
    return {code: 0, data};
  } catch (e) {
    console.log(e.response.data.errors);
    if (e.response) {
      return {code: -1, error: e.response.data.errors[0].descripcion};
    } else {
      return {code: -1, error: "Error desconocido"};
    }
  }
}
