import React from 'react'
import { usePostFile } from './usePostFile'
import { OneFileSin } from '../../../../components/input/OneFileSin'
import { Button, Col, Row } from 'antd'

export const CargarXls = () => {
  const { body, setBody, onClick } = usePostFile()

  return (
    <Row gutter={10}>
      <Col><OneFileSin label='' body={body} setBody={setBody} onClick={onClick} /></Col>
      <Col>
        <Button loading={body.loading} disabled={!body.file} onClick={onClick} type='default'>Enviar XLS</Button>
      </Col>
    </Row>
  )
}
