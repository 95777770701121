import { useState } from 'react'
import { Button, Modal } from 'antd'
import { ListaServicios } from './ListaServicios'
import { useDispatch } from 'react-redux'
import { startGetServiciosRechazados } from '../../../../context/actions/facturacionActions'


export const ServiciosRechazados = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
 
  const hanldeClick = () => {
    setOpen(true)
    dispatch(startGetServiciosRechazados())
  }
  
  //
  return (
    <>
      <Button onClick={hanldeClick} type="link">Ver Rechazos</Button>
      <Modal footer={[<Button danger size='small' key={1} onClick={() => setOpen(false)} >cerrar</Button>]} onCancel={() => setOpen(false)} open={open} title="Servicios Rechazados">
        <ListaServicios />
      </Modal>
    </>
  )
}
