import pjson from "../../package.json";

export const ver = pjson.version;
export const nameProject = pjson.name;

export const { ambiente } = window["runConfig"];
export const { URL_BASE } = window["runConfig"];


export const URL_LOGIN = `${URL_BASE}/api/auth/login`;
export const URL_BASE_VER = `${URL_BASE}/api/Ver`;
export const URL_BASE_ACCESOPROVEEDORES = `${URL_BASE}/api/AccesoProveedores`;
export const URL_GENERICOS = `${URL_BASE}/api/genericos`;
export const URL_SELFSERVICE_SERVICIOS = `${URL_BASE}/api/SelfServiceServicios`;
export const URL_SELFSERVICE_OP = `${URL_BASE}/api/SelfServiceOp`;
export const URL_SELFSERVICE_OP_FILES = `${URL_BASE}/api/SelfServiceOpFiles`;




export const { HDI_URL } =  window['runConfig']
