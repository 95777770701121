import React from "react";
import CardPage from "../../../layout/CardPage";

export const Instrucciones = () => {
  return (
    <CardPage style={{padding: 20}}>
    <div className="tituloInstrucciones">Lineamientos pra creación de CFDI</div>

      <div className="renglonInstruccioneds">
        <b>Receptor </b>
        <div>Escotel Especialistas en contacto telefónico SA de CV</div>
        <div>EEC-081222-FH8</div>
        <div>José María Velasco 13 oficina 301, col. San José Insurgentes</div>
        <div>C.P. 03900, Ciudad de México, CDMX</div>
      </div>
      <div style={{ marginTop: 20 }}>
        En materia de CFDI: Se solicita que el USO adecuado de CFDI vaya acorde
        con la utilidad que se le dará al bien o al servicio. <br />
        En el caso de los CFDI de proveedores para los conceptos de bienes y
        servicios de asistencia los lineamientos son los siguiente:
      </div>

      <div style={{ marginTop: 20 }}>
        <b>Clave para uso de CFDI</b>
      </div>

      <table className="tabla">
        <tr>
          <td className="tituloTabla2">Clave</td>
          <td className="tituloTabla2">Descripción</td>
          <td className="tituloTabla2">Concepto</td>
        </tr>
        <tr>
          <td className="rowTabla2">G01</td>
          <td className="rowTabla2">Adquisición de mercancias</td>
          <td className="rowTabla2">
            En sentido estricto aplica servicios de grúas, Traslados y servicios
            de asistencia
          </td>
        </tr>
      </table>

      <div style={{ marginTop: 20 }}>
        <b> Formas de Pago y Método de Pago</b>
        <br />
        Las formas correctas de pago que indiquen en el CFDI estarán a lo
        siguiente <br />
        PPD= Se usará cuando el pago se haga mediante un crédito comercial y
        este no se pague en el mes en el que emitió la factura o el momento en
        el que se emita (PROVEEDORES)
      </div>

      <div style={{ marginTop: 20 }}>
        <table className="tabla">
          <tr>
            <td className="tituloTabla2">Forma de Pago</td>
            <td className="tituloTabla2">Método de Pago </td>
          </tr>
          <tr>
            <td className="rowTabla2">
              PPD o Pago en parcialidades o deferido
            </td>
            <td className="rowTabla2">99 por definir</td>
          </tr>
        </table>
      </div>

      <div style={{ marginTop: 20 }}>
        <b>Clave de Productos</b>
      </div>

      <table className="tabla">
        <tr>
          <td className="tituloTabla2">Servicio</td>
          <td className="tituloTabla2">Clave de Servicio</td>
          <td className="tituloTabla2">Retención</td>
        </tr>
        <tr>
          <td className="rowTabla2">Grúa, Traslados</td>
          <td className="rowTabla2">78101803, 7215450</td>
          <td className="rowTabla2">Ret 4%</td>
        </tr>

        <tr>
          <td className="rowTabla2">Grúas Servicio Muerto</td>
          <td className="rowTabla2">78181505</td>
          <td className="rowTabla2"></td>
        </tr>

        <tr>
          <td className="rowTabla2">
            Asistencia Vial (Maniobra, cambio de llanta, Paso de corriente)
          </td>
          <td className="rowTabla2">78181500</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Suministro de Gasolina</td>
          <td className="rowTabla2">78181701</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Hogar Electricidad</td>
          <td className="rowTabla2">72151500</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Hogar Carrejaría</td>
          <td className="rowTabla2">72101505</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia plomería</td>
          <td className="rowTabla2">72101510</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Médica (medico a domicilio)</td>
          <td className="rowTabla2">85121502</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Enfermería</td>
          <td className="rowTabla2">85101601</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Psicología</td>
          <td className="rowTabla2">85121608</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Dental</td>
          <td className="rowTabla2">85122000</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Médica (ambulancia)</td>
          <td className="rowTabla2">85121600</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Ambulancia </td>
          <td className="rowTabla2">85101508</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Paramédicos</td>
          <td className="rowTabla2">85101507</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Taxi</td>
          <td className="rowTabla2">78111804</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Funeraria</td>
          <td className="rowTabla2">85171500</td>
          <td className="rowTabla2"></td>
        </tr>
        <tr>
          <td className="rowTabla2">Asistencia Legal</td>
          <td className="rowTabla2">94131603</td>
          <td className="rowTabla2"></td>
        </tr>
      </table>

      <div style={{ marginTop: 20 }}>
        Los proveedores RESICO que sean personas físicas que nos facturen
        deberán de desglosar la retención del 1.25% que marca la ley ISR en el
        Articulo 113-J
        <br />
        Los proveedores que cambie al Régimen Fiscal de Confianza (RESICO), en
        cualquier momento del año, nos tendrán que proporcionar su aviso de
        actualización al RESICO que marca la<br /> Regla 3.13.1 de la Resolución
        Miscelánea Fiscal para 2022.
      </div>

      <div style={{ marginTop: 20 }}>
        <b>Comprobantes Digitales</b>
      </div>

      <table className="tabla">
        <tr>
          <td className="tituloTabla2">Tipo de comprobante</td>
          <td className="tituloTabla2">Descripción </td>
        </tr>
        <tr>
          <td className="rowTabla2">Ingreso</td>
          <td className="rowTabla2">
            Todos lo que se vean implicados y que formen parte de todo lo que se
            adquiera como Mercancía, Servicio y Gastos, que estén sujetos a las
            reglas del USO de CFDI antes mencionados Formato PDF y XML
          </td>
        </tr>

        <tr>
          <td className="rowTabla2">Complementos de pagos</td>
          <td className="rowTabla2">
            Emisión de complementos de pago a más tardar 5 días del mes
            siguiente.
          </td>
        </tr>
        <tr>
          <td className="rowTabla2">
            CFDI de Traslado o Ingreso con Complemento Carta Porte
          </td>
          <td className="rowTabla2">
            Se debe recibir y verificar el CFDI de traslado con complemento
            Carta Porte como una extensión dentro de la factura.
          </td>
        </tr>
        <tr>
          <td className="rowTabla2">verificación de comprobantes</td>
          <td className="rowTabla2">Expedida por el SAT Formato PDF</td>
        </tr>
        <tr>
          <td className="rowTabla2">Constancia de situación fiscal</td>
          <td className="rowTabla2">
            actualización de información Formato PDF
          </td>
        </tr>
        <tr>
          <td className="rowTabla2">Caratula de estado de cuenta bancaria</td>
          <td className="rowTabla2">Datos bancarios proveedor Formato PDF</td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </CardPage>
  );
};
