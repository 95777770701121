import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startGetQR } from "../../../../context/actions/asistenciasActions";

export const QR = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const { qr } = useSelector((state) => state.asistenciasReducer);

  useEffect(() => {
    dispatch(startGetQR(asistenciaID));
  }, [dispatch, asistenciaID]);

  if (qr === "" || !qr) return <div> - </div>;

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{fontSize: 18, fontWeight: 600}}>Confirmación de Servicio</div>
      <img
        style={{ height: 150, margin: "auto" }}
        src={`data:image/png;base64,${qr}`}
        alt="QR"
      />
    </div>
  );
};
