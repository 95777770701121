import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

export const OneFileSin = ({ body, setBody, label = "Buscar Archivo" }) => {
  //
  const props = {
    beforeUpload: (file) => {
      setBody({ ...body, file });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  //
  return (
    <>
      <div className="label">{label}</div>
      <div>
        <Upload {...props} style={{ width: "100%" }}>
          <Button style={{ textAlign: "left" }} icon={<UploadOutlined />}>
            Seleccione Archivo
          </Button>
        </Upload>
      </div>

      <small style={{ color: "orange", marginLeft: 10 }}>
        {body.file && body.file.name}
      </small>
    </>
  );
};
