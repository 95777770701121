import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

export const MotivoRechazo = ({ motivo }) => {
    if (!motivo || motivo === "")
        return <></>
    return (
        <div className='facturaRechazada blink'>
            <Tooltip title={`Motivo: ${motivo}`}><QuestionCircleOutlined />
              {' '}  Factura Rechazada</Tooltip></div>
    )
}


