import { axiosClientSigsa } from "../lib/axios/axiosClientSigsa";
import { useEffect, useState } from "react";
import { ver } from "../constants/url";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  handleVersion,
  showModal,
} from "../context/actions/facturacionActions";
import { TituloModal } from "../components/tituloModal/TituloModal";

export const VersionFront = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { isValidVersion, showModal: openModal } = useSelector(
    (state) => state.facturacionReducer
  );

  const getVerionCurrent = async () => {
    const result = await axiosClientSigsa("ver/getFrontProveedoresVer");
    return result.data;
  };

  const onCancelModal = () => 
    dispatch(showModal(false));
  

  useEffect(() => {
    getVerionCurrent().then((v) => {
      if (v !== ver) {
        dispatch(handleVersion(false));
      }
      setLoading(false);
    });
  }, []);

  if (loading) return null;
  const open = openModal === "show" && !isValidVersion;

  return (
    <div>
      <Modal
        title={<TituloModal texto="Nueva Verison" />}
        okText={"Si"}
        cancelText={"No"}
        open={open}
        onCancel={onCancelModal}
        onOk={() => window.location.reload()}
      >
        <p>Existe una nueva versión de la aplicación <br />¿Deseas recargar el sitio para obtenerla?</p>
      </Modal>
    </div>
  );
};
