import React from "react";
import { File } from "./_File";
import { Respuesta } from "./Respuesta";
import { Button } from "antd";
import { useProps } from "./useProps";

export const FormaFilesComplemento = ({ orden }) => {
  const { body, propsXml, propsPdf, disabled, submit } =
    useProps(orden);

  return (
    <div>
      <File
        props={propsXml}
        titulo="Cargar Complemento XML"
        file={body.facturaXml}
        fileErr={body.facturaXmlErr}
      />
      <File
        props={propsPdf}
        titulo="Cargar Complemento PDF"
        file={body.facturaPdf}
        fileErr={body.facturaPdfErr}
      />

      <Button
        loading={body.loading}
        style={{ margin: "auto" }}
        onClick={submit}
        disabled={disabled}
        type="primary"
      >
        Enviar Archivos{" "}
      </Button>

      <Respuesta body={body} />
    </div>
  );
};
