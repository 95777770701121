import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startGetServiciosPorValidar } from '../../../../context/actions/facturacionActions'
import { dateFromString } from '../../../../utils/dateFromString'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { SinRegistros } from '../../../../components/sinRegistros/SinRegistros'


export const ListaServicios = () => {
  const dispatch = useDispatch()

  const { serviciosPorValidar } = useSelector(state => state.facturacionReducer)

  useEffect(() => {
    dispatch(startGetServiciosPorValidar())

    var inter = setInterval(() => {
      dispatch(startGetServiciosPorValidar())

    }, 1000 * 60 * 3) // 3 minutos

    return () => 
      clearInterval(inter)
    

  }, [dispatch])
  

  return (<div style={{ padding: 10 }}>
    <Row gutter={10} style={{ marginTop: 10 }}>
      <Col className='tituloGrid' span={2}>AsistenciaID</Col>
      <Col className='tituloGrid' span={2}>Fecha</Col>
      <Col className='tituloGrid' span={3}>Servicio</Col>
      <Col className='tituloGrid' span={5}>Origen</Col>
      <Col className='tituloGrid' span={5}>Destino</Col>
      <Col className='tituloGrid' span={4}>Estatus</Col>
      <Col className='tituloGrid' span={3}>Costo</Col>
    </Row>

    {serviciosPorValidar.length === 0 ? <SinRegistros /> : serviciosPorValidar.map((s, i) => (<Row key={i} gutter={10} style={{ marginTop: 20 }}>
      <Col className='' span={2}>{s.asistenciaID}</Col>
      <Col className='' span={2}>{dateFromString(s.fechaLlamadaIniciada).soloFecha}</Col>
      <Col className='' span={3}>{s.servicio}</Col>
      <Col className='' span={5}>{s.municipioOrigen}</Col>
      <Col className='' span={5}>{s.municipioDestino}</Col>
      <Col className='' span={4}>{s.estatusServicioPorValidar}</Col>
      <Col className='' span={3} style={{ textAlign: 'right' }}>{formatCurrency(s.costo)}</Col>
    </Row>))}


  </div>)

}
