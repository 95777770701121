import { Button, Modal } from 'antd'
import React from 'react'
import { TituloModal } from '../../../components/tituloModal/TituloModal'
import { MasInfoItem } from './_MasInfoItem'

export const ModalMasInfo = ({ open, setOpen, asistencia }) => {


    return (
        <Modal
            title={<TituloModal texto="Información del Servicio" />}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[<Button key="back" danger size='small' onClick={() => setOpen(false)}>Cerrar</Button>]}
        >
            

           <MasInfoItem campo={asistencia.campo1} />
           <MasInfoItem campo={asistencia.campo2} />
           <MasInfoItem campo={asistencia.campo3} />
           <MasInfoItem campo={asistencia.campo4} />
           <MasInfoItem campo={asistencia.campo5} />
           <MasInfoItem campo={asistencia.campo6} />
           <MasInfoItem campo={asistencia.campo7} />
           <MasInfoItem campo={asistencia.campo8} />
           <MasInfoItem campo={asistencia.campo9} />
           <MasInfoItem campo={asistencia.campo10} />
           <MasInfoItem campo={asistencia.campo11} />
           <MasInfoItem campo={asistencia.campo12} />
       
        </Modal>
    )
}
