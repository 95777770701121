import { Button, Modal, Tooltip } from 'antd'
import React from 'react'
import { TituloModal } from '../../../components/tituloModal/TituloModal'

export const Servicios = ({ orden }) => {
    const [open, setOpen] = React.useState(false)

const plural = orden.asistencias.length === 1 ? 'servicio' : 'servicios'
    return (<>
        <Tooltip title="Ver los servicios de la orden"><div onClick={() => setOpen(true)} className='serviciosEnOrden'>{orden.asistencias.length}</div></Tooltip>

        <Modal
            footer={[<Button onClick={() => setOpen(false)} key={1} size='small' danger >cerrar</Button>]}
            open={open}
            onCancel={() => setOpen(false)}
            title={<TituloModal texto={`Orden ${orden.ordenPagoID} con ${orden.asistencias.length} ${plural}`} />}>

            <div style={{ maxHeight: 500, overflowY: 'auto' }}>

                {orden.asistencias.map((a, i) => (
                    <div key={i} className='listaAsistencias'>
                        {a}
                    </div>
                ))}


            </div>
        </Modal>
    </>)
}
