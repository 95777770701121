import React from 'react'
import { getFileExt } from '../../../../utils/getFileExt';
import { URL_SELFSERVICE_OP_FILES } from '../../../../constants/url';
import { postFile } from '../../../../utils/fetch';

import { useDispatch } from 'react-redux';
import { startGetOrdenesBiertasByProveedorID } from '../../../../context/actions/facturacionActions';

export const useProps = (orden) => {
    const dispatch = useDispatch()
    const [body, setBody] = React.useState({})

    const propsXml = {
        beforeUpload: (file) => {
            if (getFileExt(file.name).toLowerCase() !== 'xml') {
                setBody({ ...body, facturaXml: null, facturaXmlErr: `El archivo ${file.name} no es XML` });
                return false;
            } else {
                setBody({ ...body, facturaXml: file, facturaXmlErr: null });
            }
            return false;
        },
        maxCount: 1,
        showUploadList: false,
    };

    const propsPdf = {
        beforeUpload: (file) => {
            if (getFileExt(file.name).toLowerCase() !== 'pdf') {
                setBody({ ...body, facturaPdf: null, facturaPdfErr: `El archivo ${file.name} no es PDF` });
                return false;
            } else if (body.verificacion?.name === file.name) {
                setBody({ ...body, facturaPdf: null, facturaPdfErr: `El archivo ${file.name} no puede ser igual al de verificación` });
                return false;
            } else {
                setBody({ ...body, facturaPdf: file, facturaPdfErr: null });
            }
            return false;
        },
        maxCount: 1,
        showUploadList: false,
    };

    const propsVerificacion = {
        beforeUpload: (file) => {
            if (getFileExt(file.name).toLowerCase() !== 'pdf') {
                setBody({ ...body, verificacion: null, verificacionErr: `El archivo ${file.name} no es PDF` });
                return false;
            } else if (body.facturaPdf?.name === file.name) {
                setBody({ ...body, verificacion: null, verificacionErr: `El archivo ${file.name} no puede ser igual al de factura PDF` });
                return false;
            } else {
                setBody({ ...body, verificacion: file, verificacionErr: null });
            }
            return false;
        },
        maxCount: 1,
        showUploadList: false,
    };

    const disabled = !body.facturaXml || !body.facturaPdf || !body.verificacion

    const submit = async () => {
  

        setBody({ ...body, respuestaOk: null, respuestaErr: null, loading: true });
        const formData = new FormData();
        formData.append('FacturaXML', body.facturaXml);
        formData.append('FacturaPDF', body.facturaPdf);
        formData.append('VerificacionFactura', body.verificacion);

        formData.append("ordenPagoID", orden.ordenPagoID)

        const resp = await postFile(`${URL_SELFSERVICE_OP_FILES}/PostDocumento3`, formData)

        if (resp.code === 0) {
            setBody({ respuestaOk: resp.data, loading: false });
            
            setTimeout(() => {
                dispatch(startGetOrdenesBiertasByProveedorID())
            }, 5000);
            
         
        } else {
            setBody({ ...body, respuestaErr: resp.error, loading: false });
        }

    }
    
    return { body, propsXml, propsPdf, propsVerificacion, disabled, submit }
}
