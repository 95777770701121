import { Badge, Button, Col, Row, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { startConfirmarProveedor } from "../../../context/actions/asistenciasActions";
import { startUpdateServicioGnp } from "../../../context/actions/gnpApiActions";
import ModalPage from "../../../layout/ModalPage";
import { truncateString } from "../../../utils/truncateString";
import { Crono } from "./Crono";
import { FormaAceptarServicio } from "./FormaAceptarServicio";
import FormaPropuestaModal from "./FormaPropuestaModal";
import { ModalMasInfo } from "./_ModalMasInfo";
import imgMaps from '../../../assets/IconMaps.jpeg'

const RowAsistenciaPorConfirmar = ({ asistencia, provAsignado }) => {
  const dispatch = useDispatch();

  const [openModalCarretero, setOpenModalCarretero] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [asistenciaSelected, setAsistenciaSelected] = useState();
  const [asistenciaFullSelected, setAsistenciaFullSelected] = useState({});

  const [openMasInfo, setOpenMasInfo] = useState(false);


  const masInfoClick = (asistencia) => {
    setAsistenciaFullSelected(asistencia);
    setOpenMasInfo(true);
  }

  const openMap = (asistencia, tipo) => {
    let url = "";
    if (tipo === "origen") {
      url = `https://www.google.com/maps/search/?api=1&query=${asistencia.latOrigen},${asistencia.lngOrigen}`;
    } else {
      url = `https://www.google.com/maps/search/?api=1&query=${asistencia.latDestino},${asistencia.lngDestino}`;
    }
    window.open(url, "_blank");
  };

  const onClick = (asistencia) => {
    setAsistenciaSelected(asistencia.asistenciaID);

    if (asistencia.localCarretero === "local") {
      setOpenModal(true);
    }

    if (asistencia.localCarretero === "carretero") {
      setOpenModalCarretero(true);
    }
  };

  const handleOk = () => {
    dispatch(startConfirmarProveedor(asistenciaSelected, asistencia));

    if (asistenciaSelected.esGnpApi)
      // confirmaos en api de gnp
      dispatch(
        startUpdateServicioGnp({
          folioAsignacion: asistenciaSelected.folioGnp,
          estatusActual: "EA",
        })
      );
    setOpenModal(false);
  };

  return (
    <>
      <Badge.Ribbon
        text={
          !provAsignado && !asistencia.propuesta
            ? asistencia.localCarretero
            : provAsignado && asistencia.localCarretero === 'carretero'
              ? "NO"
              : asistencia.propuesta && "Prop enviada"
        }
        color={
          !provAsignado && !asistencia.propuesta
            ? asistencia.localCarretero === "local"
              ? "green"
              : "blue"
            : provAsignado
              ? "red"
              : "#faad14"
        }
      >
        <Row
          gutter={10}
          className="rowAsistencias"
          style={{ marginBottom: 15, cursor: "default" }}


        >
          <Col span={2}>
            {!provAsignado && (
              <Crono fechaSugiereProveedor={asistencia.fechaSugiereProveedor} />
            )}
          </Col>
          <Col span={4} style={{ paddingTop: 4 }}>
            {truncateString(
              asistencia.nombreLlamada +
              " " +
              asistencia.apellidoPaternoLlamada +
              " " +
              asistencia.apellidoMaternoLlamada,
              26
            )}
          </Col>

          <Col span={4} style={{ paddingTop: 4 }}>
            {asistencia.servicioNombre}
          </Col>
          <Col span={5} style={{ paddingTop: 4 }}>
           <Tooltip title="Ver en Maps" ><img onClick={()=>openMap(asistencia, "origen")} src={imgMaps}  alt="mapa" className="mapaIcon" /></Tooltip> {asistencia.municipioOrigen}, {asistencia.estadoOrigen}
          </Col>

          <Col span={5} style={{ paddingTop: 4 }}>
          <Tooltip title="Ver en Maps" ><img src={imgMaps} onClick={()=>openMap(asistencia, "destino")}  alt="mapa" className="mapaIcon" /></Tooltip>  {asistencia.municipioDestino}, {asistencia.estadoDestino}
          </Col>
          <Col span={4}>
            <Space>
              <Button onClick={()=>masInfoClick(asistencia)} size="small" type="default" danger>+ Info</Button>
              <Button onClick={()=>onClick(asistencia)} size="small" type="primary">Tomar</Button>
            </Space>
          </Col>
        </Row>
      </Badge.Ribbon>

      <ModalMasInfo open={openMasInfo} setOpen={setOpenMasInfo} asistencia={asistenciaFullSelected}  />

      {/* //Modal locales */}
      {!provAsignado && (
        <ModalPage
          handleOk={handleOk}
          handleCancel={() => setOpenModal(false)}
          isVisible={openModal}
          titulo="Tomar Servicio - LOCAL"
          okLabel="Aceptar Servicio"
        >
          <FormaAceptarServicio />
        </ModalPage>
      )}

      {/* //Modal carretero */}
      {!asistencia.propuesta && (
        <FormaPropuestaModal
          open={openModalCarretero}
          setOpenModalCarretero={setOpenModalCarretero}
          asistenciaId={asistencia.asistenciaID}
        />
      )}
    </>
  );
};

export default RowAsistenciaPorConfirmar;
