import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { ListaServicios } from './ListaServicios'

export const ServiciosAutorizados = () => {
  return (
    <div style={{padding: 10}}>
      <div className='titulo_lista'>
        Servicios Autorizados
        <Tooltip title="Puede facturar estos servicios, recuerde que solo tiene este mes poara hacerlo">
          <QuestionCircleOutlined
            style={{ color: 'orange', marginLeft: 5, fontSize: 18 }}
            className='eyeIcon' />
        </Tooltip>
      </div>
      <ListaServicios />
    </div>
  )
}
