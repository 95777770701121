
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { startGetServiciosPorGenerarOp } from '../../../../context/actions/facturacionActions'

export const useGetServicios = () => {
    const dispatch = useDispatch()

    //
    useEffect(() => {
        dispatch(startGetServiciosPorGenerarOp())
    }, [dispatch])

    useEffect(() => {
        const interval = setInterval(() =>
            dispatch(startGetServiciosPorGenerarOp()), 1000 * 60 * 3) // 3 minutos

        return () => clearInterval(interval)

    }, [dispatch])

    return {}
}
