import React from 'react'
import { Button, Checkbox, Col, Row } from 'antd'
import { dateFromString } from '../../../../utils/dateFromString'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { SinRegistros } from '../../../../components/sinRegistros/SinRegistros'
import { useGetServicios } from './useGetServicios'
import { useArreglo } from './useArreglo'

//
export const ListaServicios = () => {


  const { serviciosPorGenerarOp, onChange, handleClick, arreglo, loadingCrearOP } = useArreglo()
  useGetServicios()

  //
  return (<div style={{ padding: 10 }}>
    <Row gutter={10} style={{ marginTop: 10 }}>
      <Col className='tituloGrid' span={1}></Col>
      <Col className='tituloGrid' span={3}>AsistenciaID</Col>
      <Col className='tituloGrid' span={3}>Fecha Servicio</Col>
      <Col className='tituloGrid' span={3}>Servicio</Col>

      <Col className='tituloGrid' span={3}>Costo</Col>
    </Row>

    {serviciosPorGenerarOp.length === 0 ? <SinRegistros /> : serviciosPorGenerarOp.map((s, i) => (<Row key={i} gutter={10} style={{ marginTop: 20 }}>
      <Col span={1}><Checkbox name={s.servicioPorValidarID} onChange={onChange} /></Col>
      <Col className='' span={3}>{s.asistenciaID}</Col>
      <Col className='' span={3}>{dateFromString(s.fechaLlamadaIniciada).soloFecha}</Col>
      <Col className='' span={3}>{s.servicio}</Col>

      <Col className='' span={3} style={{ textAlign: 'right' }}>{formatCurrency(s.costo)}</Col>
    </Row>))}

    <Button
    loading={loadingCrearOP}
      disabled={arreglo.length === 0}
      type='primary'
      style={{ width: 200, marginTop: 20 }}
      onClick={handleClick}>Generar Orden de Pago</Button>
  </div>)
}
