import { Col, Row } from 'antd';
import React from 'react'

export const TablaHeader = () => {
    return (
        <Row gutter={10} style={{ marginBottom: 15 }}>
            <Col span={2} className="tituloTabla">
                Time
            </Col>
            <Col span={4} className="tituloTabla">
                Titular
            </Col>
            <Col span={4} className="tituloTabla">
                Servicio
            </Col>
            <Col span={5} className="tituloTabla">
                Origen
            </Col>
            <Col span={5} className="tituloTabla">
                Destino
            </Col>
            <Col span={4} className="tituloTabla">
                Acciones
            </Col>
        </Row>
    );
};
