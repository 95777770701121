import { Col, Row } from 'antd';
import React from 'react'

export const MasInfoItem = ({campo}) => {

    if(campo=== "" || !campo  || campo.includes("Placas" || "Placa" || "placas" || "placa" || "limitesCobertura" ))
        return <></>

    const campos = campo.split(":");

  return (
   <Row gutter={30} className='rowCampos' justify="center">
    <Col span={10} className='colLabelCampos'>{campos[0]}</Col>
    <Col span={10} className='colValueCampos'>{campos[1]}</Col>
   </Row>
  )
}
