
import { Col, Input, Row } from 'antd'
import { dateFromString } from '../../../../utils/dateFromString'
import { useLista } from './useLista'

export const ListaServicios = () => {

    const { listaFinal, onChange } = useLista()

    //
    return (
        <div style={{ maxHeight: 400, overflowY: 'auto' }}>
            <Input onChange={onChange} size='small' placeholder='buscar...' style={{ marginBottom: 5 }} />
            <div style={{ color: "orange", fontSize: 12, marginBottom: 10 }}>últimos 3 meses únicamente</div>
            {listaFinal.map((s, i) => (<div key={i}>
                <Row style={{ marginBottom: 10, borderBottom: '1px solid #f1f1f1' }}>
                    <Col span={12}><b>Servicio: </b>{s.asistenciaID}</Col>
                    <Col span={12}><b>Fecha:</b> {dateFromString(s.fechaSolicitud).f1}</Col>

                    <Col span={24}><b>Primer análisis: </b> {s.comentariosEvaluacion}</Col>
                    <Col span={24}><b>Conclusión: </b>{s.comentariosConclusion}</Col>
                </Row>
            </div>))}
        </div>
    )
}
