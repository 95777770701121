import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { ListaServicios } from './ListaServicios'

export const ServiciosPendientes = () => {

  //
  return (
    <div style={{padding: 10}}>
      <div className='titulo_lista'>
        Servicios Esperando Autorización
        <Tooltip title="Servicios que estan en espera de ser autorizados para poder crear la factura correspondiente">
          <QuestionCircleOutlined
            style={{ color: 'orange', marginLeft: 5, fontSize: 18 }}
            className='eyeIcon' />
        </Tooltip>
      </div>
      <ListaServicios />
    </div>
  )
}
