import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import LabelNumber from "../../../components/input/LabelNumber";
import { SelectSucursales } from "../../../components/select/SelectSucursales";
import { setConfirmaAsistenciaData } from "../../../context/actions/asistenciasActions";

export const FormaAceptarServicio = ({ setData }) => {
  const dispatch = useDispatch();
  const changeSelect = (proveedorSucursalID) => {
    dispatch(setConfirmaAsistenciaData({ proveedorSucursalID }));
  };


  // Como se deja fijo 60 minutos, se comenta la función handleChange y se manda en useEffect
  // const handleChange = ({ target }) => {
  //   const tiempoLlegada = Number(target.value);
  //   dispatch(setConfirmaAsistenciaData({ tiempoLlegada }));
  // };

  useEffect(() => {
    dispatch(setConfirmaAsistenciaData({ tiempoLlegada: 60 }));
  }, [dispatch]);

  return (
    <div>
  
    <Row gutter={10}>
      <Col span={12}>
        <SelectSucursales label="Sucursal encargada" onChange={changeSelect} />
      </Col>
      <Col span={12}>
      <div style={{padding: 10, color:'#ca2228', textAlign: 'center'}}>Acepto llegar <br />en menos de 60 minutos</div>
      </Col>
      {/* <Col span={12}>
        <LabelNumber  label="Tiempo de llegada" onChange={handleChange} />
      </Col> */}
    </Row>
    </div>
  );
};
