import React, { useEffect, useState } from "react";
import { LabelValor } from "../../../../components/label/LabelValor";
import { useSelector } from "react-redux";
import { getCronoTimeSin } from "../../../../utils/getCronoTime";
import { dateFromString } from "../../../../utils/dateFromString";

export const TiempoTranscuridoArribo = () => {
  const { fechaAsignacion, fechaArribo } = useSelector(
    (state) => state.asistenciasReducer.asistencia
  );

  const [dif, setDif] = useState();

  useEffect(() => {
    const inter = setInterval(() => {
      setDif(getCronoTimeSin(fechaAsignacion));
    }, 1);

    return () => clearInterval(inter);
  }, [fechaAsignacion, fechaArribo]);

  if(fechaArribo) return <></>;

  return (
    <LabelValor
      label={`Tiempo Trasncurrido desde el ${
        dateFromString(fechaAsignacion).f1
      } `}
      valor={` ${dif} `}
    />
  );
};
