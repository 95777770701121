import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useLista = () => {
    const { serviciosRechazados } = useSelector(state => state.facturacionReducer)
    const [listaFinal, setListaFinal] = React.useState([])

    useEffect(() => { setListaFinal(serviciosRechazados) }, [serviciosRechazados])

    const onChange = ({ target }) => {
        const value = target.value.toLowerCase()
        const filtered = serviciosRechazados.filter(s => s.asistenciaID.toLowerCase().includes(value))
        setListaFinal(filtered)
    }

    return { listaFinal, onChange }
}
