import { types } from "../types/types";
import { composeErrorNotification, composeSuccessNotification } from "../../utils/composeNotification";
import { get, getFile, post } from "../../utils/fetch";
import { URL_SELFSERVICE_OP, URL_SELFSERVICE_SERVICIOS } from "../../constants/url";
import { notification } from "./notificationActions";



// Get Lista de servicios por valdiuar

export const startGetServiciosPorValidar = () => {
  return async (dispatch) => {
    const url = `${URL_SELFSERVICE_SERVICIOS}/GetServiciosPorValidar`
    try {
      const { data } = await get(url)
      dispatch(storeServiciosPorValidar(data))
    } catch (error) {
      composeErrorNotification(error.response.data)
    }
  }
}

const storeServiciosPorValidar = (data) => ({
  type: types.storeServiciosPorValidar,
  payload: data
})

// lista de servicios por generar op
export const startGetServiciosPorGenerarOp = () => {
  return async (dispatch) => {


    const url = `${URL_SELFSERVICE_SERVICIOS}/GetServiciosPorGenerarOp`
    try {
      const { data } = await get(url)
      dispatch(storeServiciosPorGenerarOp(data))
    } catch (error) {
      composeErrorNotification(error.response.data)
    }
  }
}

const storeServiciosPorGenerarOp = (data) => ({
  type: types.storeServiciosPorGenerarOp,
  payload: data
})

export const startGetServiciosRechazados = () => {
  return async (dispatch) => {
    const url = `${URL_SELFSERVICE_SERVICIOS}/GetServiciosRechazados`
    try {
      const { data } = await get(url)
      dispatch(storeServiciosRechazados(data))
    } catch (error) {
      composeErrorNotification(error.response.data)
    }
  }
}

const storeServiciosRechazados = (data) => ({
  type: types.storeServiciosRechazados,
  payload: data
})


// control de verisones
export const handleVersion = (value) => {
  return (dispatch) => {
    dispatch(handleModal(value))
  }
}


export const showModal = (value) => {
  return (dispatch) => {
    dispatch(showModalAction(value))
  }
}


export const handleModal = (value) => {
  return {
    type: types.handleModalVersion,
    payload: value
  };
}

export const showModalAction = (value) => {
  return {
    type: types.showModal,
    payload: value
  }
}

export const startPostCrearOP = (body) => {
  return async (dispatch) => {
    dispatch(setLoadingCrearOP(true))
    const url = `${URL_SELFSERVICE_OP}/PostCrearOP`
    const res = await post(url, body)

    if (res.code === 0) {
      dispatch(notification(composeSuccessNotification(res.data)))
      dispatch(startGetServiciosPorGenerarOp())
    } else {
      notification(composeErrorNotification(res.error))
    }
    dispatch(setLoadingCrearOP(false))

  }
}

const setLoadingCrearOP = (value) => ({
  type: types.setLoadingCrearOP,
  payload: value
})

export const startGetOrdenesBiertasByProveedorID = () => {
  return async (dispatch) => {
    const url = `${URL_SELFSERVICE_OP}/GetOrdenesBiertasByProveedorID`
    try {
      const { data } = await get(url)
      dispatch(storeOrdenesBiertas(data))
    } catch (error) {
      notification(composeErrorNotification(error.response.data))
    }
  }
}

const storeOrdenesBiertas = (data) => ({
  type: types.storeOrdenesAbiertas,
  payload: data
})

/// 
export const startDownloadDocumentoOP = (ordenPagoID, tipo) => {
  return async (dispatch) => {
    let nombre = "file.pdf";
    if (tipo === 1) nombre = `Comprobante _De_Pago_${ordenPagoID}.pdf`;

    const url = `${URL_SELFSERVICE_OP}/DownloadDocumentoOP/${ordenPagoID}/${tipo}`
    const result = await getFile(url, nombre);

    if (result.code === -1)
      notification(composeErrorNotification(result.error))



  }
}