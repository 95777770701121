import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startPostCrearOP } from '../../../../context/actions/facturacionActions'

export const useArreglo = () => {
const dispatch = useDispatch()
    const [arreglo, setArreglo] = React.useState([])
  const { serviciosPorGenerarOp, loadingCrearOP } = useSelector(state => state.facturacionReducer)

  // 
  const onChange = ({ target }) => {
    if (target.checked) {
      setArreglo([...arreglo, target.name])
    } else {
      setArreglo(arreglo.filter(a => a !== target.name))
    }
  }

  const handleClick = () => {
    const  body= {serviciosPorValidarID: [...arreglo]};
    dispatch(startPostCrearOP(body))
  }

  return {serviciosPorGenerarOp, onChange, handleClick, arreglo, loadingCrearOP}
}
