import React from 'react'
import CardPage from '../../../layout/CardPage'
import { CargarXls } from './cargarXls/CargarXls'
import { Col, Divider, Row } from 'antd'
import { ServiciosRechazados } from './serviciosRechazados/ServiciosRechazados'
import { ServiciosPendientes } from './serviciosPendientes/ServiciosPendientes'
import { ServiciosAutorizados } from './serviciosAutorizados/ServiciosAutorizados'

export const Servicios = () => {
  return (
    <CardPage>
      <Row gutter={10}>
        <Col xs={24} sm={24} lg={10} xl={8} xxl={6}><CargarXls /></Col>
        <Col xs={4} ><ServiciosRechazados /></Col>
      </Row>
      <Row gutter={[10]}>
        <Col xs={24}><ServiciosPendientes /></Col>
        <Divider />

        <Col xs={24}><ServiciosAutorizados /></Col>
      </Row>
    </CardPage>
  )
}
