import { CheckCircleOutlined } from '@ant-design/icons'
import React from 'react'

export const NombreFile = ({ file }) => {
    if (!file) return <></>
    return (
        <div style={{ color: "green", marginLeft: 10, fontSize:14, marginTop: 5 }}>
           <CheckCircleOutlined /> {file.name}
        </div>
    )
}
