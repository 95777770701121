import { CloseCircleOutlined } from '@ant-design/icons'
import React from 'react'

export const ErrorFile = ({ error }) => {

  if (!error) return <div></div>

  return (
    <div style={{ color: "red", marginLeft: 10, marginTop: 5, fontSize: 12 }}>
      <CloseCircleOutlined /> {error}
    </div>

  )
}
