import { Button } from 'antd'
import { File } from './_File'
import { useProps } from './useProps'
import { Respuesta } from './Respuesta'


//
export const FormaFilesFactura = ({ orden }) => {

    const { body, propsXml, propsPdf, propsVerificacion, disabled, submit } = useProps(orden)


    //
    return (
        <>
            <File props={propsXml} titulo="Cargar Factura XML" file={body.facturaXml} fileErr={body.facturaXmlErr} />
            <File props={propsPdf} titulo="Cargar Factura PDF" file={body.facturaPdf} fileErr={body.facturaPdfErr} />
            <File props={propsVerificacion} titulo="Cargar Verificacion SAT" file={body.verificacion} fileErr={body.verificacionErr} />

            <Button loading={body.loading} style={{ margin: 'auto' }} onClick={submit} disabled={disabled} type='primary'>Enviar Archivos </Button>

            <Respuesta body={body} />
        </>
    )
}
