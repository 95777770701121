import { Button, Modal } from "antd";
import React from "react";
import { TituloModal } from "../../../components/tituloModal/TituloModal";
import { FormaFilesComplemento } from "./formaFilesComplemento/FormaFilesComplemento";

export const ModalCargaComplemento = ({ open, setOpen, orden }) => {
  return (
    <Modal
      open={open}
      footer={[
        <Button onClick={() => setOpen(false)} size="small" danger>
          cerrar
        </Button>,
      ]}
      onCancel={() => setOpen(false)}
      title={<TituloModal texto="Carga de Complemento de Pago" />}
    >
      <FormaFilesComplemento orden={orden} />
    </Modal>
  );
};
