import React, { useEffect } from 'react'
import CardPage from '../../../layout/CardPage'
import { Listado } from './_Listado'
import { useDispatch } from 'react-redux'
import { startGetOrdenesBiertasByProveedorID } from '../../../context/actions/facturacionActions'



export const Ordenes = () => {
  const dispacth = useDispatch()
  useEffect(() => {
    dispacth(startGetOrdenesBiertasByProveedorID())

    var timer = setInterval(() => {
      dispacth(startGetOrdenesBiertasByProveedorID())
      console.log('actualizando ordenes')
    }, 1000 * 60 * 3) // 3 minutos

    return () => {
      clearInterval(timer)
    }

  },[dispacth])

  return (
    <CardPage>
      <Listado />
    </CardPage>
  )
}
