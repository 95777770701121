import { URL_BASE_ACCESOPROVEEDORES, ver } from "../../constants/url";

import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, getFile, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";
import { HDI_CLIENT_ID, HDI_FECHAS } from "../../constants/hdiConst";
import dayjs from "dayjs";
import { hdiClient } from "../../lib/axios/axiosClientSigsa";

//////////////////////////////////////////////////////////////////
export const startGetAsistenciasPorConfirmar = () => {
  return async (dispatch) => {
    const url = `${URL_BASE_ACCESOPROVEEDORES}/GetAsistenciasPorConfirmar`;

    const response = await get(url);

    if (response.code !== -1) {
      dispatch(storeAsistenciasPorConfirmar(response.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetAsistenciasPorConfirmar", response.error)
        )
      );
    }
  };
};

const storeAsistenciasPorConfirmar = (asistencias) => ({
  type: types.storeAsistenciasPorConfirmar,
  payload: asistencias,
});

//////////////////////////////////////////////////////////////////
export const startGetAsistenciasVivas = () => {
  return async (dispatch) => {
    const url = `${URL_BASE_ACCESOPROVEEDORES}/GetAsistenciasVivas`;

    const response = await get(url);

    if (response.code !== -1) {
      dispatch(storeAsistenciasVivas(response.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetAsistenciasVivas", response.error)
        )
      );
    }
  };
};

const storeAsistenciasVivas = (asistencias) => ({
  type: types.storeAsistenciasVivas,
  payload: asistencias,
});

//////////////////

//////////////

export const setConfirmaAsistenciaData = (data) => ({
  type: types.setConfirmaAsistenciaData,
  payload: data,
});

///////////////////

export const startConfirmarProveedor = (asistenciaID, asistencia = {}) => {
  return async (dispatch, getState) => {
    const nombreComercial =
      getState().proveedorInfoReducer.proveedorInfo.proveedor.nombreComercial;
    const { tiempoLlegada, proveedorSucursalID } =
      getState().asistenciasReducer.confirmaAsistenciaData;

    if (asistencia?.clienteID === HDI_CLIENT_ID) {
      const fecha = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      const bodyHdi = {
        numeroReporteHdi: asistencia?.validador1,
        numeroReporteProveedor: asistencia.asistenciaID,
        tipoFecha: HDI_FECHAS.fechaAsignacion,
        version: ver,
        cliente: "proveedores",
        fecha,
        informacionVial: {
          nombreComercial,
          idTipoTiempoSubEvento:
            asistencia.localCarretero === "local"
              ? 32
              : asistencia.localCarretero === "carretero"
              ? 33
              : "",
          fechaArribo: dayjs()
            .add(tiempoLlegada, "minute")
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          sFechaArribo: dayjs()
            .add(tiempoLlegada, "minute")
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            .toString(),
          tiempoArribo: tiempoLlegada,
        },
        comentario: {
          comentario: "Asignando proveedor",
          fecha,
        },
      };
      const data = await hdiClient.put("notificacion", bodyHdi);
      console.log({ resultHDI: data.data });
    }

    const body = { tiempoLlegada, proveedorSucursalID, asistenciaID };
    //const url = `${URL_BASE_ACCESOPROVEEDORES}/ConfirmarProveedor`;
    const url = `${URL_BASE_ACCESOPROVEEDORES}/ConfirmarProveedor2`;
    const result = await post(url, body);

    console.log({ result });
    if (result.code === 0) {
      dispatch(startGetAsistenciasVivas());
      dispatch(startGetAsistenciasPorConfirmar());
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(notification(composeErrorNotification(`${result.error}`)));
    }
  };
};

export const setOpenLateral = (state) => ({
  type: types.setOpenLateral,
  payload: state,
});

////////////////////////////////////////////////

export const startGetAsistencia = (asistenciaID, noMuestraCargando) => {
  return async (dispatch, getState) => {
    dispatch(storeAsistencia({}));

    if (!noMuestraCargando) dispatch(setCargandoAsistencia(true));

    const url = `${URL_BASE_ACCESOPROVEEDORES}/GetAsistencia/${asistenciaID}`;
    const response = await get(url);

    if (response.code !== -1) {
      dispatch(storeAsistencia(response.data));
    } else {
      dispatch(
        notification(composeErrorNotification("GetAsistencia", response.error))
      );
    }
    dispatch(setCargandoAsistencia(false));
  };
};

const storeAsistencia = (asistencia) => ({
  type: types.storeAsistencia,
  payload: asistencia,
});

const setCargandoAsistencia = (state) => ({
  type: types.setCargandoAsistencia,
  payload: state,
});
////

export const startGetArchivos = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ACCESOPROVEEDORES}/GetArchivos/${asistenciaID}`;
    const response = await get(url);

    if (response.code !== -1) {
      dispatch(storeArchivos(response.data));
    } else {
      dispatch(
        notification(composeErrorNotification("GetArchivos", response.error))
      );
    }
  };
};

const storeArchivos = (archivos) => ({
  type: types.storeArchivos,
  payload: archivos,
});

//////
export const startGetArchivo = (asistenciaID, nombreArchivo) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ACCESOPROVEEDORES}/GetArchivo/${asistenciaID}/${nombreArchivo}`;
    const result = await getFile(url, nombreArchivo);

    if (result.code === -1) {
      dispatch(
        notification(composeErrorNotification("GetArchivo", `${result.data}`))
      );
    }
  };
};

///////////
export const startPostArribo = (asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const body = { asistenciaID, usuarioID };
    const url = `${URL_BASE_ACCESOPROVEEDORES}/PostArribo`;

    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetAsistencia(asistenciaID, true));
    } else {
      dispatch(
        notification(composeErrorNotification("PostArribo", `${result.error}`))
      );
    }
  };
};

///////////
export const startPostConcluido = (asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const body = { asistenciaID, usuarioID };
    const url = `${URL_BASE_ACCESOPROVEEDORES}/PostConcluido`;

    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("PostConcluido", `${result.error}`)
        )
      );
    }
  };
};

///////////

export const startGetQR = (asistenciaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ACCESOPROVEEDORES}/GetQR/${asistenciaID}`;
    const result = await get(url);

    dispatch(storeQR(result.data));
  };
};

const storeQR = (qr) => ({
  type: types.storeQR,
  payload: qr,
});
