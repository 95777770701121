export const getCronoTime = (time, less) => {

    const f = new Date(time)
    const mm = Number(f.getMinutes()) > 9 ? f.getMinutes() : `0${f.getMinutes()}`;
    const ss = Number(f.getSeconds()) > 9 ? f.getSeconds() : `0${f.getSeconds()}`;
    const mil = Number(
        f.getMilliseconds()) > 9 ? (f.getMilliseconds() > 99 ? f.getMilliseconds() : `0${f.getMilliseconds()}`) : `00${f.getMilliseconds()}`;

    return `${mm - Number(less)}:${ss}:${mil}`

}

export const getCronoTimeSin = (ini) => {


// Fecha dada
const fechaDada = new Date(ini);


   const fin = new Date()


// Diferencia en milisegundos
const diferencia = fin - fechaDada;

// Convertir la diferencia a días, horas, minutos y segundos
const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

const ss = segundos > 9 ? segundos : `0${segundos}`;
const mm = minutos > 9 ? minutos : `0${minutos}`;




return `${dias} días, ${horas} horas, ${mm} minutos y ${ss} segundos`;

}
