export const composeErrorNotification = (message, description) => {
  return {
    type: "error",
    message,
    description,
    dateTime: Date.now(),
    duration: 3.5,
  };
};

export const composeInfoNotification = (description) => {
  return {
    type: "info",
    message: "Error en la Acción",
    description,
    dateTime: Date.now(),
    duration: 2.5,
  };
};

export const composeSuccessNotification = (description, duracion = 3) => {
  return {
    type: "success",
    message:"Acción Exitosa",
    description,
    dateTime: Date.now(),
    duration: duracion,
  };
};
